import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
	selector: 'app-email-confirm-modal',
	templateUrl: './email-confirm-modal.component.html',
	styleUrls: ['./email-confirm-modal.component.scss']
})
export class EmailConfirmModalComponent {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: EmailConfirmModalComponent,
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
