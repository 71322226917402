import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const hulalaCustomerGroup = 'hulala';
export const customerGroup = hulalaCustomerGroup;

const supportEmail = {
	hulala: 'hulala@smoothr.de'
};
const firebaseConfig = {
	hulala: {
		apiKey: 'AIzaSyCe_Tq3LIsvNQjQoZQao-1bE8nXIvYTxog',
		authDomain: 'hulala-web-app.firebaseapp.com',
		projectId: 'hulala-web-app',
		storageBucket: 'hulala-web-app.appspot.com',
		messagingSenderId: '289080434275',
		appId: '1:289080434275:web:c34d1dcbfb14af1ac6e256',
		measurementId: 'G-53J0CWFV2W'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyA6dNJBwkQkDIiJjkuiuqyCz3J6pPhKIpM',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	hasRecommendations: true,
	...apiEnvironment
};
