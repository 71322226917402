import {AfterViewInit, Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import Article from 'src/smoothr-web-app-core/models/Article';
import Compound from 'src/smoothr-web-app-core/models/Compound';
import Localization from '../../../smoothr-web-app-core/models/Localization';
import {numberD} from 'src/smoothr-web-app-core/utils/utils';

interface NutritionValue {
	$numberDecimal: string;
}
export interface Nutrition {
	portionInG: NutritionValue;
	energyKcalPerPortion: NutritionValue;
	energyKcalPer100g: NutritionValue;
	energyKjPerPortion: NutritionValue;
	energyKjPer100g: NutritionValue;
	fatPerPortion: NutritionValue;
	fatPer100g: NutritionValue;
	saturatesPerPortion: NutritionValue;
	saturatesPer100g: NutritionValue;
	carbohydratePerPortion: NutritionValue;
	carbohydratePer100g: NutritionValue;
	sugarPerPortion: NutritionValue;
	sugarPer100g: NutritionValue;
	proteinPerPortion: NutritionValue;
	proteinPer100g: NutritionValue;
	saltPerPortion: NutritionValue;
	saltPer100g: NutritionValue;
}

export type ArticleWithNutrition = Article & {nutritions: Nutrition};
@Component({
	selector: 'app-allergens-info-modal',
	templateUrl: './allergens-info-modal.component.html',
	styleUrls: ['allergens-info-modal.component.scss']
})
export class AllergensInfoModalComponent implements AfterViewInit {
	article: ArticleWithNutrition;
	compound: any;
	environment = environment;
	numberD = numberD;

	constructor(private modalCtrl: ModalController) {}
	ngAfterViewInit(): void {
		console.log(Object.values(this.compound.allergens).find(it => it === true));
		console.log(Object.values(this.compound.additives).find(it => it === true));
	}
	static async show(modalCtrl: ModalController, article: Article) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: AllergensInfoModalComponent,
			componentProps: {
				article,
				compound: article?.compound
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens)
			return Object.values(article?.compound?.allergens)?.find(
				it => it === true
			);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives)
			return Object.values(article?.compound?.additives)?.find(
				it => it === true
			);
		else return null;
	}
	isHaveAnyValueMoreZero(article: ArticleWithNutrition) {
		return Object.values(article.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
