import {Component, EventEmitter, Input, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {environment} from '../../../environments/environment';
import {
	getAvailability,
	getPrice,
	numberD,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {Nutrition} from 'src/smoothr-web-app-core/models/Nutritions';

@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss']
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	@Output() openInfo = new EventEmitter<Article>();
	numberToCurrency = numberToCurrency;
	di = DisplayIdentifier;

	constructor() {}

	isHidden(article: Article): boolean {
		return (
			this.preorderType &&
			!getAvailability(article, OrderType.PREORDER, this.preorderType)
		);
	}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(
			selectedOption => selectedOption.group === this.optionGroup._id
		);
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(
			article => article._id === this.selected[indexSelection].article._id
		);
	}

	onSelectionChange(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	price(option: Article): number {
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		const minOfGroup = Math.min(
			...this.optionGroup.articles.map(it =>
				getPrice(it, OrderType.PREORDER, this.preorderType)
			)
		);
		// if (
		// 	this.optionGroup.displayIdentifiers
		// 	&& this.optionGroup.displayIdentifiers.find(identifire => identifire === DisplayIdentifier.sides)
		// ) {
		// 	if (price === minOfGroup) {
		// 		return 0;
		// 	} else {
		// 		return price - minOfGroup;
		// 	}
		// }

		return price;
	}

	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(
				di => di === DisplayIdentifier.fullPrice
			) >= 0
		);
	}

	openInfoClick(option: Article) {
		this.openInfo.emit(option);
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens)
			return Object.values(article?.compound?.allergens)?.find(
				it => it === true
			);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article) {
		return Object.values(
			(article as Article & {nutritions: Nutrition})?.nutritions
		)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}

	isAddit(article: Article) {
		if (article?.compound?.additives)
			return Object.values(article?.compound?.additives)?.find(
				it => it === true
			);
		else return null;
	}

	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit_25')
			? 'deposit25'
			: '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit_15')
			? 'deposit15'
			: '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit_8')
			? 'deposit8'
			: '';

		return deposit15 || deposit25 || deposit8;
	}
	// price(option: Article): number {
	// 	console.log(this.article.name.de);
	// 	let price = getPrice(option, OrderType.PREORDER, this.preorderType);
	// 	if (this.fullPrice()) {
	// 		price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
	// 	}
	// 	// if (this.article.groupDependencies.map(it => it.dependencies.map(it => it.groupArticles)).includes(this.optionGroup._id))
	// 	// check if optiongroup has dependencies to fulfill
	// 	const allDependingAritcles = [];
	// 	this.article.groupDependencies.forEach(it => {
	// 		it.dependencies.forEach(dep => {
	// 			allDependingAritcles.push(dep.articles);
	// 			allDependingAritcles.push();
	// 		});
	// 	});
	// 	const groupDependenciesIdsArray = [].concat.apply(
	// 		[],
	// 		this.article.groupDependencies.reduce(
	// 			(acc, val) => acc.concat(val.dependencies.map(it => it.groupArticles)),
	// 			[]
	// 		)
	// 	);
	// 	const optionIds = this.optionGroup.articles.map(it => it._id);
	// 	let hasDependencyInGroup = false;
	// 	for (const optionId of optionIds) {
	// 		hasDependencyInGroup = groupDependenciesIdsArray.includes(optionId);
	// 		if (hasDependencyInGroup) {
	// 			break;
	// 		}
	// 	}
	// 	if (!hasDependencyInGroup) {
	// 		if (
	// 			this.optionGroup.displayIdentifiers?.includes(
	// 				DisplayIdentifier.sides
	// 			) === true
	// 		) {
	// 			const minOfGroup = Math.min(
	// 				...this.optionGroup.articles.map(it =>
	// 					getPrice(it, OrderType.PREORDER, this.preorderType)
	// 				)
	// 			);
	// 			price -= minOfGroup;
	// 		}
	// 		return price;
	// 	}
	// 	if (this.calculationPrice(option) > 0) {
	// 		price = this.calculationPrice(option);
	// 	}

	// 	return price;
	// }
}
