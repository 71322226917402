import {HomePage} from 'src/app/pages/home/home.page';
import {environment} from 'src/environments/environment';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {MapPage} from 'src/app/pages/map/map.page';

@Component({
	selector: 'app-toolbar-logo',
	templateUrl: './toolbar-logo.component.html',
	styleUrls: ['./toolbar-logo.component.scss']
})
export class ToolbarLogoComponent {
	@Input() venue: Venue;
	@Input() order: Order;
	@Input() showCross = false;
	@Output() close = new EventEmitter<void>();
	@Output() navigateMap = new EventEmitter<void>();
	environment = environment;
	pt = PreorderType;
	constructor(
		private router: Router,
		public repository: RepositoryService
	) {}
	home() {
		this.router.navigate(['map'], {
			replaceUrl: true
		});
	}
	closeWindow() {
		this.close.emit();
	}
	goToMap() {
		this.navigateMap.emit();
	}
}
