import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, Platform} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Api} from '../smoothr-web-app-core/api/api';
import {MaintenancePage} from './pages/maintenance/maintenance.page';
import {SwUpdate} from '@angular/service-worker';

declare var cookieConsentData: any;
declare var cookieConsentCallback: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;

	constructor(
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AngularFireAnalytics,
		platform: Platform,
		private translate: TranslateService,
		private swUpdate: SwUpdate
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		const favIcon: any =
			document.querySelector("link[rel*='icon']") ||
			document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/favicon.png';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		document.title = translate.instant(
			'index.title.' + environment.customerGroup
		);
		translate.setDefaultLang('de');
		translate.use('de');

		this.initCookieConsent();

		swUpdate.available.subscribe(() => {
			const snack = this.snackbarCtrl.open(
				'Wir updaten grade deine App. Hab einen moment Geduld.',
				null,
				{
					duration: 2000
				}
			);
			snack.afterDismissed().subscribe(() => {
				window.location.reload();
			});
		});
		if (swUpdate.isEnabled) {
			console.log('Checking for App update');
			swUpdate
				.checkForUpdate()
				.finally(() => console.log('Update checking finished'));
		} else {
			swUpdate.activateUpdate().finally(async () => {
				console.log('Updates activated result: ' + swUpdate.isEnabled);
				if (swUpdate.isEnabled) {
					console.log('Checking for App update');
					await swUpdate.checkForUpdate();
				} else {
					console.log('SwUpdate is disabled (no service worker)');
				}
			});
		}
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					MaintenancePage.navigate(this.router);
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	ngOnInit() {}

	async handleCookieConsent() {
		console.log('handleCookieConsent()');
		AppComponent.analyticsEnabled = cookieConsentData.targeting;
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	private async initCookieConsent() {
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant(
					'cookie_consent.title.' + environment.customerGroup
				),
				cookies_policy_url: this.translate.instant(
					'cookie_consent.policy_url.' + environment.customerGroup
				)
			});
		};
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', onContentLoaded);
		} else {
			await onContentLoaded();
		}
		cookieConsentCallback = async () => {
			console.log(cookieConsentData);
			await this.handleCookieConsent();
		};
		await this.handleCookieConsent();
	}
}
