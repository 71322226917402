import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-modal-info-small',
	templateUrl: './modal-info-small.component.html',
	styleUrls: ['./modal-info-small.component.scss']
})
export class ModalInfoSmallComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	ngOnInit(): void {}

	static async show(modalCtrl: ModalController) {
		const resultScan = await modalCtrl.create({
			component: ModalInfoSmallComponent,
			cssClass: 'edit-table-modal auto-height'
		});
		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(200);
		return res;
	}

	toMapPage() {
		this.modalCtrl.dismiss('map');
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
