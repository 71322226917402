import {CustomerAuth} from './../../../smoothr-web-app-core/models/CustomerAuth';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {Router} from '@angular/router';
import {SignUpPage} from '../../pages/sign-up/sign-up.page';
import {PopoverController, ModalController} from '@ionic/angular';
import {HomePage} from '../../pages/home/home.page';
import {MyOrdersPage} from '../../pages/my-orders/my-orders.page';
import {AccountPage} from '../../pages/account/account.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import {TosPage} from '../../pages/tos/tos.page';
import {ImpressumPage} from '../../pages/impressum/impressum.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {FaqPage} from 'src/app/pages/faq/faq.page';
import {SignInOrderPage} from 'src/app/pages/sign-in-order/sign-in-order.page';
import {SelectTableEmployeeComponent} from 'src/employee-module/components/select-table-employee/select-table-employee.component';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from 'src/smoothr-web-app-core/utils/utils';
import {MenuPage} from 'src/app/pages/menu/menu.page';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {MapPage} from 'src/app/pages/map/map.page';
enum Language {
	GreatBritain = 'en',
	German = 'de'
}
@Component({
	selector: 'app-menu-modal',
	templateUrl: './menu-modal.component.html',
	styleUrls: ['./menu-modal.component.scss']
})
export class MenuModalComponent extends RepositoryDirective implements OnInit {
	environment = environment;
	languageEnum = Language;
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private modalController: ModalController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService,
		private modalCtrl: ModalController
	) {
		super(repository);
	}
	ngOnInit() {
		super.ngOnInit();
		console.log(this.authLogin);
	}

	async signIn() {
		await this.modalController.dismiss();
		await SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.modalController.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.modalController.dismiss();
		await AccountPage.navigate(this.router);
	}

	async myOrders() {
		await this.modalController.dismiss();
		MyOrdersPage.navigate(this.router);
	}

	async logout() {
		try {
			if (this.customer) {
				this.repository.customerAuth.emit(null);
			}
			if (this.authLogin) {
				this.repository.authLogin.emit(null);
			}
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_success'),
				null,
				{
					duration: 2000
				}
			);
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_error'),
				null,
				{
					duration: 2000
				}
			);
		}
		await this.modalController.dismiss();
	}

	async home() {
		await this.modalController.dismiss();
		await HomePage.navigate(this.router);
	}

	getUrlPrivacy() {
		let currentLang = this.translate.currentLang;
		return currentLang === 'de'
			? '../../../assets/pdf/DE_Privacy.pdf'
			: '../../../assets/pdf/EN_Privacy.pdf';
	}
	async privacy() {
		await this.modalController.dismiss();
	}

	getUrlTos() {
		let currentLang = this.translate.currentLang;
		return currentLang === 'de'
			? '../../../assets/pdf/DE_AGB.pdf'
			: '../../../assets/pdf/EN_AGB.pdf';
	}
	async tos() {
		await this.modalController.dismiss();
	}

	getUrlImpressum() {
		let currentLang = this.translate.currentLang;
		return currentLang === 'de'
			? '../../../assets/pdf/DE_Impressum.pdf'
			: '../../../assets/pdf/EN_Imprint.pdf';
	}
	async impressum() {
		await this.modalController.dismiss();
	}
	async allergens() {
		await this.modalController.dismiss();
		// TODO open PDF
	}
	async faq() {
		await this.modalController.dismiss();
		FaqPage.navigate(this.router);
	}
	async signInEmployee() {
		await this.modalController.dismiss();
		SignInOrderPage.navigate(this.router);
	}
	async tableNumberChange() {
		let venue = this.venue;
		if (!venue) {
			venue = (await this.repository.getAllVenues()).find(ven => {
				return venueAcceptsOrders(ven, PreorderType.INSIDE);
			});
		}
		if (venue) {
			const result = await SelectTableEmployeeComponent.show(
				this.modalCtrl,
				this.order,
				venue
			);
			if (result) {
				if (
					this.order &&
					this.order.tableNumber !== 'preorder_table' &&
					this.order.preorder.type === PreorderType.INSIDE
				) {
					this.order.tableNumber = result.number;
					this.order.table = result._id;
					this.repository.order.emit(this.order);
				} else {
					this.repository.createOrder(venue, null, PreorderType.INSIDE, null);
					sleep(100);
					this.order.tableNumber = result.number;
					this.order.table = result._id;
					this.repository.order.emit(this.order);
				}
				await this.MenuPage();
			}
		}
	}
	dismiss() {
		this.modalController.dismiss();
	}
	async MenuPage() {
		await this.modalController.dismiss();
		MenuPage.navigate(this.router);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async goToMap() {
		await this.modalController.dismiss();
		MapPage.navigate(this.router);
	}
}
