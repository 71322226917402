import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

export enum EditTableEmit {
	map = 'map',
	qr = 'qr'
}

@Component({
	selector: 'app-edit-table-modal',
	templateUrl: './edit-table-modal.component.html',
	styleUrls: ['./edit-table-modal.component.scss']
})
export class EditTableModalComponent {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const resultScan = await modalCtrl.create({
			component: EditTableModalComponent,
			cssClass: 'edit-table-modal auto-height'
		});
		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(200);
		return res;
	}

	toMapPage() {
		this.modalCtrl.dismiss('map');
	}

	openQr() {
		this.modalCtrl.dismiss('qr');
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
