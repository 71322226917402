import {environment} from './../../../environments/environment.prod';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Location} from '@angular/common';

@Component({
	selector: 'app-account-toolbar',
	templateUrl: './account-toolbar.component.html',
	styleUrls: ['account-toolbar.component.scss']
})
export class AccountToolbarComponent implements OnInit {
	@Input()
	title: string;
	@Input()
	icon: string;
	@Input()
	src: string;
	@Output() back = new EventEmitter<void>();
	environment = environment;
	constructor(private location: Location) {}

	ngOnInit() {}
	goBack() {
		this.location.back();
	}
}
